<template>
  <div class="wrap">
    <homeSwiper />
    <div class="module1">
      <div class="title">{{ $t("index.热销产品") }}</div>
      <div class="product-container">
        <div
          class="product-item"
          v-for="(product, index) in hotProduct"
          :key="index"
          @click="gotoProductDetail(product.urlCode)"
        >
          <!-- 这里放置产品内容 -->
          <img :src="product.coverUrl" alt="" class="image" />
          <div class="text">{{ product.productName }}</div>
        </div>
      </div>
    </div>
    <div class="title" style="background: rgba(22, 22, 22, 1); color: #fff">
      {{ $t("index.应用行业") }}
    </div>
    <eazySwiper />
    <div class="module2" v-if="platform.isPC">
      <div class="banner">
        <div>{{ $t("index.新闻动态") }}</div>
        <div class="allnews" @click="goNewsList">
          {{ $t("index.查看全部新闻") }} <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="body" v-if="fristNews">
        <div class="left">
          <div class="newsimg" @click="goNewsdetail(fristNews.id)">
            <img :src="fristNews.coverUrl" alt="" class="image" />
            <div class="bgc">
              <div class="triangle" v-if="fristNews.isVideo">
                <i class="el-icon-caret-right"></i>
              </div>
            </div>
            <div class="newsTitle">{{ fristNews.title }}</div>
          </div>
        </div>
        <div class="right">
          <div
            v-for="(item, index) in newsList"
            :key="index"
            class="small"
            @click="goNewsdetail(item.id)"
          >
            <div class="smallimgBox">
              <div class="smallimgBgc">
                <div class="triangle" v-if="item.isVideo">
                  <i class="el-icon-caret-right"></i>
                </div>
              </div>
              <img :src="item.coverUrl" alt="" class="smallimg" />
            </div>
            <div class="smallData">
              <div class="smallTitle">
                {{ item.title }}
              </div>
              <div class="smallTime">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module2M" v-else>
      <div class="banner">
        <div>{{ $t("index.新闻动态") }}</div>
      </div>
      <div class="body">
        <div class="right">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="small"
            @click="goNewsdetail(item.id)"
          >
            <div class="smallimgBox">
              <img :src="item.coverUrl" alt="" class="smallimg" />
            </div>
            <div class="smallData">
              <div class="smallTitle">
                {{ item.title }}
              </div>
              <div class="smallTime">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vidoSwiper />
    <mapHome v-if="false" />
    <div class="module3" v-if="platform.isPC">
      <div class="element" v-if="false">
        <img src="@assets/imgs/home/one.png" alt="" />
        <div class="title">{{ $t("index.购买渠道") }}</div>
        <div class="gogo">
          {{ $t("index.了解更多") }} <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="element" @click="goTeachList">
        <img src="@assets/imgs/home/two.png" alt="" />
        <div class="title">{{ $t("index.维修视频") }}</div>
        <div class="gogo">
          {{ $t("index.了解更多") }} <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="element" @click="goInstructionsList">
        <img src="@assets/imgs/home/three.png" alt="" />
        <div class="title">{{ $t("index.使用说明书") }}</div>

        <div class="gogo">
          {{ $t("index.了解更多") }} <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mapHome from "@/components/map";
import homeSwiper from "./Swiper.vue";
import eazySwiper from "./eazySwiper.vue";
import vidoSwiper from "./vidoSwiper.vue";
import { getHotProduct, selectNews } from "@/service/api";
import { goto } from "@utils/goto";
import platform from "@utils/platform";
export default {
  components: {
    mapHome,
    homeSwiper,
    eazySwiper,
    vidoSwiper,
  },
  computed: {
    ...mapState(["lang"]),
    isZhCN() {
      const isCN = this.lang === "zh-CN";
      return isCN;
    },
  },
  data() {
    return {
      platform,
      menu: {},
      hotProduct: {},
      fristNews: {},
      newsList: [],
      list: [],
    };
  },
  beforeCreate() {
    localStorage.removeItem("clickMenuId");
  },
  mounted() {
    this.getlist();
    console.log(navigator.language);
  },
  methods: {
    async getlist() {
      this.hotProduct = (await getHotProduct()) || {};
      const params = {
        pageNum: 1,
        pageSize: 5,
      };
      const news = (await selectNews(params)) || {};
      this.list = news.list;
      this.fristNews = news.list[0];
      this.newsList = news.list.slice(1, 5);
      console.log(this.news);
    },
    gotoProductDetail(produceName) {
      goto("/productDetail?product=" + produceName);
    },
    goNewsList() {
      goto("/media/news");
    },
    goNewsdetail(uid) {
      goto(`/media/newsDetail?id=${uid}`);
    },
    goInstructionsList() {
      goto("/service/instructionsList");
    },
    goTeachList() {
      goto("/teach/teachList");
    },
  },
};
</script>

  
  <style lang='scss' scoped>
.title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding: 50px 0 40px;
}
.triangle {
  color: #fff;
  font-size: 30px;
  transform: scaleX(1.5);
}
.module1 {
  color: #fff;
  background-color: rgba(20, 20, 20, 1);
  .product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .product-item {
    position: relative;
    overflow: hidden;
    width: 49vw; /* 每行两个产品，每个产品占50%宽度 */
    height: 26vw;
    box-sizing: border-box; /* 盒模型包含边框和内边距 */
    margin: 0.5vw 0;
    object-fit: cover;
    .image {
      width: 100%;
      cursor: pointer;
      transition: all 0.5s ease 0.1s;
      object-fit: cover;
    }
    .text {
      width: 40vw;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 86%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: 600;
    }
  }
  .product-item:hover {
    cursor: pointer;
    .image {
      transform: scale(1.2);
    }
    // .text {
    //   font-size: 24px;
    // }
  }
}
.module2 {
  width: 100%;
  // height: 540px;
  padding: 0 12%;
  background-color: rgba(22, 22, 22, 1);
  color: #fff;

  .banner {
    padding: 50px 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    .allnews {
      font-weight: 400;
      font-size: 14px;
    }
    .allnews:hover {
      color: red;
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    .left {
      // padding-right: 25px;
      .newsimg {
        position: relative;
        .bgc {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          width: 94%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          opacity: 1; /* 初始透明度为0 */
          transition: opacity 0.3s; /* 添加过渡效果 */
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .bgc:hover {
          cursor: pointer;
          opacity: 0; /* 鼠标移入时显示阴影层 */
          .newsTitle {
            opacity: 1; /* 鼠标移入时显示阴影层 */
          }
        }
        .image {
          position: relative;
          z-index: 1;
          vertical-align: top;
          width: 94%;
        }
        .newsTitle {
          position: absolute;
          bottom: 10px;
          left: 10px;
          z-index: 11;
          font-weight: bold;
          font-size: 22px;
          white-space: nowrap; /* 文本不换行 */
          overflow: hidden; /* 隐藏溢出部分 */
          text-overflow: ellipsis; /* 使用省略号代替溢出部分 */
        }
      }
    }
    .right {
      flex: 1;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .small {
        display: flex;
        position: relative;
        width: 100%;
        height: 70px;
        .smallimgBox {
          position: relative;
          width: 128px;
          height: 70px;
          .smallimgBgc {
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            opacity: 1; /* 初始透明度为0 */
            transition: opacity 0.3s; /* 添加过渡效果 */
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .smallimg {
            position: absolute;
            top: 0;
            left: 0;
            width: 128px;
            height: 70px;
            object-fit: cover;
          }
        }
      }
      .smallData {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 0;
        padding-left: 10px;
        .smallTitle {
          font-weight: bold;
          font-size: 18px;
          white-space: nowrap; /* 文本不换行 */
          overflow: hidden; /* 隐藏溢出部分 */
          text-overflow: ellipsis; /* 使用省略号代替溢出部分 */
        }
        .smallTime {
          font-weight: 400;
          font-size: 14px;
          color: #fff;
        }
      }
      .small:hover {
        cursor: pointer;
        background-color: rgb(69, 68, 68);
        .smallimgBgc {
          opacity: 0; /* 鼠标移入时显示阴影层 */
        }
      }
    }
  }
}
.module2M {
  width: 100%;
  // height: 540px;
  padding: 0 2%;
  background-color: rgba(22, 22, 22, 1);
  color: #fff;

  .banner {
    padding: 50px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    .allnews {
      font-weight: 400;
      font-size: 14px;
    }
    .allnews:hover {
      color: red;
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    .right {
      flex: 1;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .small {
        display: flex;
        position: relative;
        width: 100%;
        height: 70px;
        margin: 10px 0;
        .smallimgBox {
          position: relative;
          width: 128px;
          height: 70px;
          .smallimg {
            width: 128px;
            height: 70px;
            object-fit: cover;
          }
        }
      }
      .smallData {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding-left: 10px;
        .smallTitle {
          font-weight: bold;
          font-size: 18px;
          white-space: wrap; /* 文本不换行 */
        }
        .smallTime {
          font-weight: 400;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}
.module3 {
  width: 100%;
  padding: 40px 10%;
  display: flex;
  background-color: rgba(20, 20, 20, 1);
  color: #fff;
  .element {
    flex: 1;
    text-align: center;
    background-color: rgba(36, 36, 36, 1);
    margin: 0 10px;
    padding: 30px 0;
    .title {
      padding: 10px 0;
      font-size: 24px;
      font-weight: 500;
    }
    .gogo {
      color: rgba(202, 202, 202, 1);
    }
    img {
      width: 10%;
    }
  }
  .element:hover {
    cursor: pointer;
    color: rgb(225, 0, 0);
    .gogo {
      color: rgb(225, 0, 0);
    }
  }
}
</style>
  