<template>
  <div class="box">
    <div class="title">{{ $t("index.视频案例") }}</div>
    <el-carousel
      type="card"
      height="500px"
      :autoplay="false"
      indicator-position="none"
      @change="handleCarouselChange"
      v-if="platform.isPC"
    >
      <el-carousel-item
        v-for="(item, index) in data"
        :key="index"
        style="display: flex; justify-content: center; align-items: center"
      >
        <img
          :src="item.coverUrl"
          class="video-cover"
          v-if="item.status === '0'"
        />
        <video
          width="100%"
          height="100%"
          autoplay
          loop
          muted
          :src="item.videoUrl"
          @loadedmetadata="showControls"
          v-else
        ></video>
      </el-carousel-item>
    </el-carousel>

    <video
      width="100%"
      height="100%"
      autoplay
      loop
      muted
      :src="dataM.videoUrl"
      @loadedmetadata="showControls"
      v-else
    ></video>
  </div>
</template>
  
  <script>
// import Video from "@/views/mediaCenter/video";
import { selectVideo } from "@/service/api";
import platform from "@utils/platform";
export default {
  name: "test",
  //   components: { Video },
  data() {
    return {
      platform,
      data: {},
      isControlsVisible: false,
      dataM: {},
    };
  },
  mounted() {
    this.Video();
  },
  methods: {
    async Video() {
      const params = {
        pageNum: 1,
        pageSize: 5,
        isHot: 1,
      };
      const dataList = (await selectVideo(params)) || {};
      this.data = dataList.list;
      this.dataM = dataList.list[2];
      console.log(this.data);
      this.data[0].status = "1";
      // console.log(this.httpUrls);
    },
    handleCarouselChange(index) {
      console.log("Carousel item changed to index:", index);
      // this.data[index].status = "1";
      this.data.forEach((item, idx) => {
        if (idx === index) {
          item.status = "1";
        } else {
          item.status = "0";
        }
      });
    },
    showControls() {
      this.isControlsVisible = true;
      // this.$refs.video.controls = this.isControlsVisible;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.box {
  background-color: rgba(22, 22, 22, 1);
  color: #fff;
  // padding-top: 50px;
  .title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding: 50px 0 30px;
  }
  .vido {
    vertical-align: top;
  }
}
/* .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */
.box::v-deep {
  .el-carousel__arrow--left {
    // left: 25%;
    transform: scale(2);
  }
  .el-carousel__arrow--right {
    // right: 25%;
    transform: scale(2);
  }
  .el-carousel__mask {
    background: none;
  }
  .el-carousel {
    overflow: hidden;
  }
  // .el-carousel__item {
  //   img {
  //     transform: scale(1.3);
  //     position: relative;
  //     left: 500px;
  //   }
  // }
  .is-active {
    transform: translateX(382px) scale(1.2) !important;
    position: relative;
    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.video-player {
  width: 100%;
  display: block;
}
.video-cover {
  width: 100%;
  display: block;
}
.hide-progress-bar::-webkit-media-controls-timeline {
  display: none;
}

.hide-progress-bar::-moz-progress-bar {
  display: none;
}

.hide-progress-bar::-ms-track {
  display: none;
}
</style>
  